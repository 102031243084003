<template>
  <div class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-block card-stretch card-height blog blog-detail">
            <div class="card-header d-flex justify-content-center">
              <div class="header-title ">
                <h5 class="card-title text-success">Omnichannel</h5>
              </div>
            </div>
            <form class="mt-4">
              <div class="card-body">
                <div class="col-sm-12 px-lg-5">
                  <div class="input-group mb-3">
                    <input type="text" v-model="form.phone" class="form-control mb-0" id="phone" name="phone" :placeholder="$t('label.phonePlaceholder')"
                      :class="{ 'is-invalid':v$.form.phone.$dirty && !v$.form.phone.$pending && v$.form.phone.$invalid }" />
                    <button @click="getCode" class=" btn btn-outline-primary" :disabled="form.phone.length < 10" type="button">
                      <i v-show="smsbtnLoader" class="fa fa-spinner fa-spin"></i>
                      {{$t('button.get')}}{{$t('button.smsCode')}}
                    </button>
                    <span v-if="( v$.form.phone.$dirty && !v$.form.phone.$pending && v$.form.phone.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}</span>
                  </div>
                  <div v-if="step === 2" class="input-group mb-3">
                    <input type="text" v-model="form.smsCode" class="form-control mb-0" id="smsCode" name="smsCode" @blur="v$.$touch()" :placeholder="$t('label.smsCodePlaceholder')"
                      :class="{ 'is-invalid': v$.form.smsCode.$error }" />
                    <span v-if="v$.form.smsCode.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
                  </div>
                </div>
                <div class="col-sm-12 px-lg-5">
                  <p>{{$t('verify.verifyPhoneDesc')}}</p>
                </div>
                <div class="d-flex justify-content-center">
                  <router-link to="/" class="shadow btn btn-outline-primary rounded-pill mb-3 me-1">
                    {{$t('button.cancel')}}</router-link>
                  <button @click="onSubmit()" type="button" :disabled="form.smsCode.length < 4" class="shadow btn btn-primary rounded-pill mb-3 me-1">
                    <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                    {{$t('button.submit')}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { getSms, verifySms } from '@/api/send'
import useVuelidate from '@vuelidate/core'
import { required, numeric } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'

export default {
  name: 'VerifyPhone',
  setup() {
    const v$ = useVuelidate()
    return { v$ }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  data() {
    return {
      step: 1,
      commomData: commomData(),
      options: {
        centeredSlides: false,
        loop: false,
        slidesPerView: 1,
        autoplay: {
          delay: 3000
        },
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      },
      smsbtnLoader: false,
      form: {
        phone: '',
        smsCode: ''
      }
    }
  },
  validations() {
    return {
      form: {
        phone: { required, numeric },
        smsCode: { required }
      }
    }
  },
  methods: {
    getCode() {
      this.smsbtnLoader = true
      const p = {
        phone: this.form.phone
      }
      if (isNaN(this.form.phone)) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: '格式錯誤'
        })
        this.smsbtnLoader = false
        return false
      }
      getSms(p)
        .then(res => {
          console.log(res)
          this.$swal.mixin().fire({
            icon: 'success',
            // text: res.message
          })
          this.smsbtnLoader = false
          this.step = 2
        })
        .catch(err => {
          this.smsbtnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    onSubmit() {
      console.log(this.form)
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.commomData.btnLoader = true
      const p = {
        captcha: this.form.smsCode
      }
      verifySms(p)
        .then(res => {
          console.log(res)
          this.$swal
            .mixin()
            .fire({
              icon: 'success',
              text: res.message
            })
            .then(r => {
              console.log(r)
              this.$router.push({ name: 'auth1.verifylicense' })
            })
          this.commomData.btnLoader = false
        })
        .catch(err => {
          this.commomData.btnLoader = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    }
  }
}
</script>
