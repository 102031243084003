import request from '../utils/request'

export function getSms(data) {
  return request({
    url: `/send/sms`,
    method: 'post',
    data
  })
}

export function verifySms(data) {
  return request({
    url: `/send/sms/verify`,
    method: 'post',
    data
  })
}

